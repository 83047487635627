import { useTranslations } from '@royalaholddelhaize/ah-next-core/src/i18n/client';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type {
    TypographyProps,
    Variant,
} from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography.interfaces';
import type { FC } from 'react';

import css from './recipe-ingredients-title.module.scss';

export interface RecipeIngredientsTitleProps
    extends Omit<TypographyProps, 'variant' | 'children'> {
    variant?: Extract<Variant, 'heading-1' | 'heading-2' | 'heading-3'>;
}

export const RecipeIngredientsTitle: FC<RecipeIngredientsTitleProps> = ({
    variant = 'heading-2',
    ...rest
}) => {
    const { t } = useTranslations('recipe-details');
    return (
        <Typography variant={variant} className={css.title} {...rest}>
            {t('ingredients.title')}
        </Typography>
    );
};
